import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "form-check",
  style: {"padding-left":"0px"}
}
const _hoisted_2 = ["id", "name", "value", "onClick"]
const _hoisted_3 = ["for"]
const _hoisted_4 = {
  key: 0,
  style: {"margin":"0px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: `option${index}`
      }, [
        _withDirectives(_createElementVNode("input", {
          id: `${_ctx.name}-option-${index}`,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedOption) = $event)),
          type: "radio",
          class: "btn-check",
          name: _ctx.name,
          value: option.value,
          onClick: ($event: any) => (_ctx.updateModelValue(option.value))
        }, null, 8, _hoisted_2), [
          [_vModelRadio, _ctx.selectedOption]
        ]),
        _createElementVNode("label", {
          class: _normalizeClass(`btn btn-sm ${_ctx.selectedOption === option.value ? 'btn-tertiary': ''}`),
          for: `${_ctx.name}-option-${index}`
        }, _toDisplayString(option.text), 11, _hoisted_3)
      ], 64))
    }), 128)),
    (_ctx.showLineBreak)
      ? (_openBlock(), _createElementBlock("hr", _hoisted_4))
      : _createCommentVNode("", true)
  ]))
}