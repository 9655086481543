
import {
    defineComponent, onBeforeMount, PropType, reactive, ref,
} from 'vue';

type State = {
    selectedValue: string;
};

export type RadioButtonOption = {
    text: string;
    value: string;
}

export default defineComponent({
    name: 'b-radio-button-group',
    props: {
        modelValue: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        options: {
            type: Array as PropType<Array<RadioButtonOption>>,
            default: () => [],
        },
        showLineBreak: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    setup(props, context) {
        const selectedOption = ref(props.modelValue);

        const state = reactive<State>({
            selectedValue: '',
        });

        function updateModelValue(newValue: string) {
            sessionStorage.setItem(state.selectedValue, newValue);
            context.emit('update:modelValue', newValue);
        }

        onBeforeMount(() => {
            const data = sessionStorage.getItem(`${state.selectedValue}`);

            if (data) {
                selectedOption.value = data;
                updateModelValue(data);
            }
        });

        return {
            selectedOption,
            updateModelValue,
        };
    },
});
