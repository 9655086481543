import { useNotification } from '@/composable/useNotifications';
import ItemType from '@/domain/ItemType';
import ItemTypeInventoryCategory from '@/domain/ItemTypeInventoryCategory';
import ItemTypeApiService from '@/services/api/ItemTypeApiService';
import masterDataStore from '@/modules/master-data/store/MasterDataStore';
import { getTitleCaseTranslation } from '@/services/TranslationService';

export default class ItemTypeService {
    private notification = useNotification();

    private store = masterDataStore.getInstance().configStore;

    private itemTypeApiService: ItemTypeApiService;

    constructor() {
        this.itemTypeApiService = new ItemTypeApiService();
    }

    private mapCategories(itemType: ItemType) {
        itemType.itemTypeInventoryCategories = itemType.sortInventoryCategories.map((x) => new ItemTypeInventoryCategory({ inventoryCategory: x }));
    }

    public async getItemTypes(): Promise<Array<ItemType>> {
        const response = await this.itemTypeApiService.getItemTypes();
        return response.success ? response.data : [];
    }

    public async addNewItemType(itemType: ItemType): Promise<boolean> {
        this.mapCategories(itemType);

        const response = await this.itemTypeApiService.addNewItemType(itemType);
        if (!response.success) {
            this.notification.showError(response.message);
        } else {
            this.store.addItemType(response.data);
            this.notification.showSuccess(`${getTitleCaseTranslation('core.common.added')} ${response.data.description}`);
        }
        return response.success;
    }

    public async updateItemType(itemType: ItemType): Promise<boolean> {
        this.mapCategories(itemType);

        const response = await this.itemTypeApiService.updateItemType(itemType);
        if (!response.success) {
            this.notification.showError(response.message);
        } else {
            this.store.updateItemType(response.data);
            this.notification.showSuccess(`${getTitleCaseTranslation('core.common.updated')} ${response.data.description}`);
        }
        return response.success;
    }

    public async deleteItemType(itemType: ItemType): Promise<boolean> {
        const response = await this.itemTypeApiService.deleteItemType(itemType);
        if (!response.success) {
            this.notification.showError(response.message);
        } else {
            this.store.deleteItemType(itemType);
            this.notification.showSuccess(`${getTitleCaseTranslation('core.common.deleted')} ${itemType.description}`);
        }
        return response.success;
    }
}
