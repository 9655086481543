
import {
    computed, defineComponent, onBeforeMount, onBeforeUnmount, reactive,
} from 'vue';
import InventoryCategory, { StaticInventoryCategory } from '@/domain/InventoryCategory';
import Shipment from '@/domain/Shipment';
import TagListener from '@/modules/floortrak/services/TagListener';
import BAdvancedTable from '@/components/bootstrap-library/table/BAdvancedTable.vue';
import { ColumnDefinition, TableDefinition } from '@/types';
import Location from '@/domain/Location';
import Transaction from '@/domain/Transaction';
import TransactionLine from '@/domain/TransactionLine';
import ItemPicker from '@/components/ItemPicker.vue';
import { ItemType as ItemTypeEnum } from '@/domain/enums/ItemType';
import Item from '@/domain/Item';
import ItemType from '@/domain/ItemType';
import QuantityPicker from '@/components/QuantityPicker.vue';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue';
import ReceivingService from '@/services/ReceivingService';
import SortStationService from '@/services/SortStationService';
import SortedItemService from '@/services/SortedItemService';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import router from '@/router';
import FloorTrakRouteTypes from '@/modules/floortrak/router/types';
import useReceivingSearch from '@/modules/floortrak/composables/useReceivingSearch';
import { useNotification } from '@/composable/useNotifications';
import FloorTrakOrbisCard from '@/components/FloorTrakOrbisCard.vue';
import SmartTrakFooter from '@/components/SmartTrakFooter.vue';
import SortStation from '@/domain/SortStation';
import SortedItem from '@/domain/SortedItem';
import SortedItemTotal from '@/domain/SortedItemTotal';
import coreStore from '@/store/CoreStore';
import SortStationActivity from '@/domain/SortStationActivity';
import ApplicationUser from '@/domain/ApplicationUser';
import DropdownAutocompleteSingleSelect from '@/components/dropdown/DropdownAutocompleteSingleSelect.vue';
import BProgressBar from '@/components/bootstrap-library/BProgressBar.vue';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import LabelGenerationService from '@/services/LabelGenerationService';
import useLoading from '@/modules/floortrak/composables/useLoading';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';
import { ReceivingAction } from '@/modules/floortrak/domain/enums/ReceivingAction';
import Permissions from '@/services/permissions/Permissions';
import BRow from '@/components/bootstrap-library/BRow.vue';
import BCol from '@/components/bootstrap-library/BCol.vue';
import ItemTypeService from '@/services/ItemTypeService';
import BFormCheckbox from '@/components/bootstrap-library/BFormCheckbox.vue';
import CompletedRepair from '@/domain/Repair/CompletedRepair';
import BFormSelect from '@/components/bootstrap-library/BFormSelect.vue';
import RepairState from '@/interfaces/RepairState';
import useItemRepair from '@/composable/useItemRepair';
import Popover from '@/components/bootstrap-library/Popover.vue';
import RepairService from '@/services/RepairService';
import BRadioButtonGroup, { RadioButtonOption } from '@/components/bootstrap-library/BRadioButtonGroup.vue';

interface ItemTableData {
    item: Item;
    name: string;
    estimatedQuantity: number;
    actualQuantity: number;
}

interface CompleteItem {
    item: Item;
    checked: boolean;
}

type State = {
    loading: boolean;
    search: number | undefined;
    sortStation: SortStation | null;
    shipment: Shipment;
    showItemSearch: boolean;
    showSearch: boolean;
    searching: boolean;
    isContinue: boolean;
    openSortStations: Array<SortStation>;
    showUserSearch: boolean;
    newUser: ApplicationUser | undefined;
    availableUsers: Array<ApplicationUser>;
    sortedItems: Array<SortedItem>;
    showSortQuantity: boolean;
    selectedSortItem: Item | undefined;
    selectedSortCategory: InventoryCategory | undefined;
    selectedSortQty: number | undefined;
    // used for advanced table. when sorted items are loaded or a load is moved to glass, the table column definition can change
    tableComponentKey: number;
    showPrintLabelsModal: boolean;
    labelQuantity: number | undefined;
    printingLabels: boolean;
    sortedItemTotals: object;
    lastSortedItem: SortedItemTotal | undefined;
    itemTypes: Array<ItemType>;
    showLoadCompleteModal: boolean;
    completeItemList: Array<CompleteItem>;
    isRevertLoadComplete: boolean;
    showRepairModal: boolean;
    repairState: RepairState;
    transactionsAllOrCurrentSelected: string;
};

export default defineComponent({
    name: 'sort',
    components: {
        Popover,
        BFormSelect,
        BSpinner,
        BFormInput,
        QuantityPicker,
        ItemPicker,
        BAdvancedTable,
        FloorTrakOrbisCard,
        SmartTrakFooter,
        DropdownAutocompleteSingleSelect,
        BProgressBar,
        BRow,
        BCol,
        BFormCheckbox,
        BRadioButtonGroup,
    },
    props: {
        transactionNumber: {
            type: String,
            required: false,
            default: undefined,
        }, // this is a route param, it has to be a string => convert to number onSearch
    },
    setup(props) {
        const receivingService = new ReceivingService();
        const sortStationService = new SortStationService();
        const sortedItemService = new SortedItemService();
        const itemTypeService = new ItemTypeService();
        const labelGenerationService = new LabelGenerationService();
        const repairService = new RepairService();
        const { userLocation, id: userId } = coreStore.getInstance().profileStore;
        const { inventoryCategoryStore } = coreStore.getInstance();
        const notification = useNotification();
        const { confirm } = useDialogBox();
        const { setLoading } = useLoading();
        const transactionAllOrCurrentOptions: RadioButtonOption[] = [
            { text: getTitleCaseTranslation('core.selectOptions.allTransactions'), value: 'all' },
            { text: getTitleCaseTranslation('core.selectOptions.currentTransaction'), value: 'current' },
        ];

        const glassInventoryCategory = inventoryCategoryStore.getGlassCategory();
        const finishedGoodsInventoryCategory = inventoryCategoryStore.getFinishedGoodsCategory()!;
        const scrapInventoryCategory = inventoryCategoryStore.getScrapCategory()!;
        const repairInventoryCategory = inventoryCategoryStore.getRepairCategory()!;

        const initialState: State = {
            loading: true,
            search: undefined,
            sortStation: null,
            shipment: new Shipment(),
            showItemSearch: false,
            showSearch: true,
            searching: false,
            isContinue: false,
            openSortStations: [],
            showUserSearch: false,
            newUser: undefined,
            availableUsers: [],
            sortedItems: [],
            showSortQuantity: false,
            selectedSortItem: undefined,
            selectedSortCategory: undefined,
            selectedSortQty: undefined,
            tableComponentKey: 0,
            labelQuantity: undefined,
            showPrintLabelsModal: false,
            printingLabels: false,
            sortedItemTotals: {},
            lastSortedItem: undefined,
            itemTypes: [],
            showLoadCompleteModal: false,
            completeItemList: [],
            isRevertLoadComplete: false,
            showRepairModal: false,
            repairState: {
                availableRepairsForItem: [],
                repairBeingWorkedOn: new CompletedRepair(),
                selectedItem: undefined,
                standardRepairPartQtyMap: new Map<number, number>(),
                repairQuantity: 0,
            },

            transactionsAllOrCurrentSelected: transactionAllOrCurrentOptions[0].value,
        };

        const state = reactive<State>({ ...initialState });

        const {
            populateRepairParts,
            submitRepair,
            updateRepairPartQty,
            repairDescription,
        } = useItemRepair(
            {
                state: state.repairState,
                userLocationId: userLocation.id,
                userId,
            },
        );

        const transaction = computed(
            (): Transaction => {
                if (state.shipment.transactions.length > 0) {
                    return state.shipment.transactions[0] as Transaction;
                }
                return new Transaction();
            },
        );
        const canSubmit = computed((): boolean => transaction.value.transactionLines?.length > 0);

        const pageTitle = computed(() => getTitleCaseTranslation('core.common.sort') + (transaction.value ? ` (${transaction.value.id})` : ''));

        function searchItem(stringArray: Array<string>) {
            // logic for scanners
            console.log(stringArray);
        }

        const tagListener = new TagListener(searchItem);

        function closeSearch() {
            state.search = undefined;
            state.showSearch = false;
        }

        async function postSearchSuccess(shipment: Shipment) {
            if (shipment.transactions[0].isEstimateFinalized) {
                state.shipment = shipment;
                closeSearch();
            } else {
                notification.showError(getTranslation('core.domain.estimateMustBeFinalized'));
            }
        }

        async function fetchSortingStations() {
            const stationResult = await sortStationService.getSortStationsByLocationIdWithActivity(userLocation.id);
            if (stationResult.success) {
                // first check if the user is active at any sort stations. if so, only allow the user to select that location
                const currentStations = stationResult.sortStations.filter((x) => x.sortStationActivity?.some((y) => y.userId === userId && !y.stoppedOn));
                if (currentStations.length) {
                    state.openSortStations = [currentStations[0]];
                } else {
                    // user isn't sorting actively yet. let user pick a location that has no activity or allows teams and the user isn't part of the team yet
                    state.openSortStations = stationResult.sortStations.filter(
                        (x) => !x.sortStationActivity?.length || (x.isTeamSort && !x.sortStationActivity.some((y) => y.userId === userId && !y.stoppedOn)),
                    );
                }

                if (state.openSortStations.length === 1) {
                    // user can only access this one station so default to it
                    // eslint-disable-next-line prefer-destructuring
                    state.sortStation = state.openSortStations[0];
                }
            }
        }

        async function fetchAvailableUsers() {
            const userResult = await sortStationService.getUsers(userLocation.id);
            if (userResult.success) {
                state.availableUsers = userResult.users;
            }
        }

        async function openUserSearch() {
            state.showUserSearch = true;
        }

        function selectNewUser(user: ApplicationUser) {
            state.newUser = user;
        }

        async function connectNewUser() {
            if (state.sortStation && state.newUser) {
                // if the user is already connected, this does no harm to the db
                // but we still want to try to connect the user to avoid concurrency issues
                await sortStationService.connectToSortStation(state.sortStation?.id, state.newUser.id);
            }
            await fetchSortingStations();
            state.showUserSearch = false;
            state.newUser = undefined;
        }

        async function disconnectUser(activity: SortStationActivity) {
            await sortStationService.disconnectFromSortStation(activity.sortStationId, activity.userId);
            await fetchSortingStations();
        }

        async function fetchSortedItems() {
            if (transaction.value?.id) {
                const sortedItemResult = await sortedItemService.getSortedItemsByTransaction(transaction.value.id);
                if (sortedItemResult.success) {
                    state.sortedItems = sortedItemResult.sortedItems;
                    state.tableComponentKey += 1;
                }
            }
        }

        async function fetchSortedTotals() {
            const showAll = state.transactionsAllOrCurrentSelected === transactionAllOrCurrentOptions[0].value;
            const sortedReportResult = await sortedItemService.getSortedItemReportByUserAndTransaction(userId, (!showAll && transaction.value) ? transaction.value.id : 0);

            if (sortedReportResult.success) {
                state.sortedItemTotals = sortedReportResult.sortedItemReport.allItems.reduce((acc, x) => {
                    acc[x.itemName] = acc[x.itemName] || [];
                    acc[x.itemName].push(x);
                    return acc;
                }, Object.create(null));

                state.lastSortedItem = sortedReportResult.sortedItemReport.lastItem;
            }
        }

        const { fetchReceivingShipment } = useReceivingSearch(postSearchSuccess);
        async function searchExisting() {
            if (state.search && state.sortStation) {
                state.searching = true;
                await fetchReceivingShipment(state.search, ReceivingAction.SORT);

                if (state.shipment?.id) {
                    // shipment loaded up, so now record this user is at this sort station and update the sorting station activity
                    await sortStationService.connectToSortStation(state.sortStation.id, userId);
                    await fetchSortingStations();
                    await fetchSortedTotals();
                    await fetchSortedItems();
                }

                state.searching = false;
            }
        }

        async function initComponent() {
            state.shipment.addTransaction(new Transaction());
            state.shipment.transactions[0].toLocation = new Location({
                id: 0,
                name: '',
            });

            await fetchSortingStations();
            await fetchAvailableUsers();

            state.itemTypes = await itemTypeService.getItemTypes();

            state.showSearch = true;
            await searchExisting();
            if (!state.shipment?.id) {
                state.showSearch = true;
            }
            state.loading = false;
        }

        onBeforeMount(async () => {
            tagListener.startListener();
            state.search = props.transactionNumber ? parseInt(props.transactionNumber, 10) : undefined;
            await initComponent();
        });

        onBeforeUnmount(() => tagListener.stopListener());

        // reloads data needed to imitate a refresh without having to re-enter the transactionId and sort station
        async function refresh() {
            state.loading = true;

            await fetchSortingStations();
            await fetchAvailableUsers();
            await fetchReceivingShipment(transaction.value.id, ReceivingAction.SORT);
            await fetchSortedTotals();
            await fetchSortedItems();

            state.loading = false;
        }

        // sort categories so that finished goods is first, and the rest are alphabetical
        function sortCategories(a: InventoryCategory, b: InventoryCategory) {
            if (finishedGoodsInventoryCategory) {
                if (a.id === finishedGoodsInventoryCategory.id) {
                    return -1;
                }
                if (b.id === finishedGoodsInventoryCategory.id) {
                    return 1;
                }
            }

            if (a.description > b.description) {
                return 1;
            }
            return -1;
        }

        const hasGlass = computed(() => glassInventoryCategory && state.sortedItems.some((x) => x.inventoryCategoryId === glassInventoryCategory.id));

        // if shipment has sorted items that are all already completed(putaway) and lines that all have actual qty set, then lock the shipment
        const isCompleted = computed(() => state.sortedItems.every((x) => x.completed) && transaction.value?.transactionLines.every((x) => x.actualQuantity > 0));

        function itemAllowsCategory(item: Item, category: InventoryCategory) {
            if (!item?.type) {
                return true;
            }
            const categories = state.itemTypes.find((type) => type.id === item.type.id)?.sortInventoryCategories;
            // if the item type doesn't have categories configured then allow all categories for that item type
            return !categories?.length ? true : categories?.some((cat) => cat.id === category.id);
        }

        const usableCategories = computed(
            (): Array<InventoryCategory> => {
                if (hasGlass.value) {
                    return [glassInventoryCategory!, repairInventoryCategory, scrapInventoryCategory];
                }

                return inventoryCategoryStore.categories
                    .filter((cat) => cat.canMove && cat.id !== glassInventoryCategory?.id && transaction.value?.transactionLines.some((line) => itemAllowsCategory(line.item, cat)))
                    .sort(sortCategories);
            },
        );

        const categoryColumnDefinitions = computed(
            (): Array<ColumnDefinition<InventoryCategory>> => usableCategories.value.map(
                (x) => ({
                    key: `category${x.id}`,
                    searchable: true,
                    label: x.description,
                    hidden: false,
                } as ColumnDefinition<any>),
            ),
        );

        // total the individual pieces sorted for this item
        function getSortedPieceTotalForItem(item: Item) {
            return state.sortedItems.filter((x) => x.itemId === item.id).reduce((acc, x) => acc + x.quantity, 0);
        }

        // total the unit load pieces sorted for this item
        function getSortedLoadTotalForItem(item: Item) {
            if (!transaction.value) {
                return 0;
            }

            // find all the unit load line items that include this item
            const filteredUnitLoadItems = transaction.value.transactionLines.filter((x) => x.item.isUnitLoad && x.item.unitLoadParts.some((y) => y.partId === item.id));

            let total = 0;
            filteredUnitLoadItems.forEach((x) => {
                const numLoads = getSortedPieceTotalForItem(x.item);
                total += x.item.unitLoadParts.filter((y) => y.partId === item.id).reduce((acc, y) => acc + y.quantity * numLoads, 0);
            });
            return total;
        }

        // total the individual pieces sorted for this item to this category
        function getSortedCategoryTotalForItem(item: Item, category: InventoryCategory) {
            return state.sortedItems.filter((x) => x.itemId === item.id && x.inventoryCategoryId === category.id).reduce((acc, x) => acc + x.quantity, 0);
        }

        // total the individual and unit load pieces sorted, only for non unit load items
        function getSortedTotal() {
            if (!transaction.value) {
                return 0;
            }

            return transaction.value.transactionLines.filter((x) => !x.item.isUnitLoad).reduce((acc, x) => acc + getSortedPieceTotalForItem(x.item) + getSortedLoadTotalForItem(x.item), 0);
        }

        async function addSortedQuantity() {
            if (transaction.value && state.selectedSortItem && state.selectedSortCategory && state.selectedSortQty !== undefined) {
                var confirmResponse = undefined;
                if (state.selectedSortQty > 1000) {
                    confirmResponse = await confirm({
                        title: getTranslation('core.common.areYouSure'),
                        message: getTranslation('core.validation.confirmSortQuantity'),
                        vHtml: true,
                    });
                }
                if (confirmResponse || state.selectedSortQty <= 1000) {
                    state.loading = true;
                    const existingQty = getSortedCategoryTotalForItem(state.selectedSortItem, state.selectedSortCategory);
                    if (existingQty + state.selectedSortQty < 0) {
                        notification.showError(getTranslation('core.validation.sortedQuantityForCategoryCannotBeNegative'));
                    } else {
                        // if we have lines that haven't been saved to the server yet, do that now
                        if (transaction.value.transactionLines.some((x) => !x.id)) {
                            const transactionResult = await receivingService.updateTransaction(transaction.value);
                            if (transactionResult.success) {
                                state.shipment.transactions[0] = transactionResult.transaction;
                            } else {
                                // we think the most likely reason for a failure is someone else changing the transaction concurrently.
                                // so try refreshing to get the most recent data
                                await fetchReceivingShipment(transaction.value.id, ReceivingAction.SORT);
                            }
                        }

                        if (isCompleted.value) {
                            notification.showError(getTranslation('core.validation.sortedTransactionIsComplete'));
                        } else {
                            // now that we know the transaction is up to date, save the sorted item
                            const newSortedItem: SortedItem = {
                                transactionId: transaction.value.id,
                                sortStationId: state.sortStation?.id ?? 0,
                                itemId: state.selectedSortItem.id,
                                inventoryCategoryId: state.selectedSortCategory.id,
                                quantity: state.selectedSortQty,
                            };

                            const updatedSortedItems = await sortedItemService.createSorteditem(newSortedItem);
                            if (updatedSortedItems.success) {
                                await fetchSortedTotals();
                                state.sortedItems = updatedSortedItems.sortedItems;
                            } else {
                                notification.showError(Object.values(updatedSortedItems.errorList!).join('<br/>'));
                            }
                        }
                    }
                    state.loading = false;
                }

                state.showSortQuantity = false;
                state.selectedSortItem = undefined;
                state.selectedSortCategory = undefined;
                state.selectedSortQty = undefined;
            }
        }

        async function loadInLineRepairs(item: Item) {
            const availableInlineRepairs = await repairService.getItemInLineRepairs(item.id);
            state.repairState.availableRepairsForItem = availableInlineRepairs;
        }

        async function doesItemHaveInHouseRepairs(item: Item) {
            const availableInHouseRepairs = await repairService.getItemInHouseRepairs(item.id);

            return availableInHouseRepairs.length > 0;
        }

        async function openAddSortedQuantity(item: Item, category: InventoryCategory) {
            const isRepairCategory = category.staticTypeIdentifier === StaticInventoryCategory.Repair;
            let inLineRepairsExist = false;
            let inHouseRepairsExist = false;

            if (isRepairCategory) {
                await loadInLineRepairs(item);
                inLineRepairsExist = state.repairState.availableRepairsForItem.length > 0;
            }

            if (isRepairCategory && !inLineRepairsExist) {
                inHouseRepairsExist = await doesItemHaveInHouseRepairs(item);
            }

            if (inLineRepairsExist) {
                state.showRepairModal = true;
                state.repairState.selectedItem = item;
            } else if ((isRepairCategory && inHouseRepairsExist) || !isRepairCategory) {
                state.showSortQuantity = true;
                state.selectedSortCategory = category;
            } else {
                notification.showError(getTranslation('core.domain.itemNotRepairable'));
            }

            state.selectedSortItem = item;
        }

        async function submitInlineRepair() {
            state.loading = true;
            const repairId = await submitRepair();
            if (repairId) {
                state.showRepairModal = false;
                state.selectedSortCategory = finishedGoodsInventoryCategory;
                state.selectedSortQty = state.repairState.repairBeingWorkedOn.quantity;

                await addSortedQuantity();

                state.repairState.repairQuantity = 0;
                state.repairState.selectedItem = undefined;
            }
            state.loading = false;
        }

        const percentCompleted = computed((): number => Math.round((transaction.value ? getSortedTotal() / transaction.value.totalEstimatedItems : 0) * 10000) / 100);
        const percentCompletedMessage = computed((): string => `${getTitleCaseTranslation('core.common.sortCompletion')}: ${percentCompleted.value}%`);

        function mapTransLinesToTableData(lines: Array<TransactionLine>): Array<ItemTableData> {
            return lines.map((line) => {
                const obj = {
                    item: line.item,
                    name: line.item.name,
                    estimatedQuantity: line.estimatedQuantity,
                    actualQuantity: line.actualQuantity,
                    sortLoadTotal: getSortedLoadTotalForItem(line.item),
                    sortPieceTotal: getSortedPieceTotalForItem(line.item),
                };
                usableCategories.value.forEach((x) => {
                    // @ts-ignore doesn't like using an index to set object properties
                    obj[`category${x.id}`] = getSortedCategoryTotalForItem(line.item, x);
                });
                return obj;
            });
        }

        const table = computed(
            (): TableDefinition<any> => ({
                items: transaction.value?.transactionLines ? mapTransLinesToTableData(transaction.value.transactionLines as Array<TransactionLine>) : [],
                // using an empty key disables remembering table settings. categories can change based on the status of sorting.
                // we don't want to remember column settings and hide a category that should be displayed
                key: '',
                name: 'Items',
                columnDefinition: [
                    {
                        key: 'name',
                        label: getTitleCaseTranslation('core.domain.name'),
                        width: '200px',
                    },
                    {
                        key: 'estimatedQuantity',
                        label: getTitleCaseTranslation('core.common.estimatedQuantityShort'),
                        width: '80px',
                    },
                    {
                        key: 'sortLoadTotal',
                        label: getTitleCaseTranslation('core.common.sortLoads'),
                        width: '80px',
                    },
                    {
                        key: 'sortPieceTotal',
                        label: getTitleCaseTranslation('core.common.sortPieces'),
                        width: '80px',
                    },
                    ...categoryColumnDefinitions.value,
                ],
            }),
        );

        function openItemSearch() {
            state.showItemSearch = true;
        }

        async function addItem(data: { item: Item; quantity: number }) {
            state.showItemSearch = false;

            if (!transaction.value) {
                return;
            }

            if (hasGlass.value && data.item.isUnitLoad) {
                // if the load has glass don't allow adding unit loads
                notification.showError(getTranslation('core.validation.unitLoadCannotBeAddedGlassFound'));
                return;
            }

            const existingLine = transaction.value.getTransactionLineByItem(data.item);
            // don't change existing items
            if (existingLine) {
                return;
            }

            let response = true;
            if (data.item.isUnitLoad) {
                // find items in the unit load that aren't on the transaction yet
                const missingParts = data.item.unitLoadParts.filter((x) => !transaction.value.getTransactionLineByItemId(x.partId));
                if (missingParts.length) {
                    const list = missingParts.map((x) => `<li>${[x.part.customerItemNumber, x.part.name].join(' | ')}</li>`);
                    response = await confirm({
                        title: getTranslation('core.common.areYouSure'),
                        message: `${getTranslation('core.validation.unitLoadContainsItemsNotInTransactionOk')} <br/><br/><ul>${list.join('')}</ul>`,
                        vHtml: true,
                    });

                    if (response) {
                        // add missing line items
                        missingParts.forEach((x) => transaction.value.addEmptyLine(x.part));
                    }
                }
            }

            if (response) {
                transaction.value.addEmptyLine(data.item);
                state.tableComponentKey += 1;
            }
        }

        function onCancel() {
            router.push({ name: FloorTrakRouteTypes.HOME });
        }

        // disconnect the user(s) at the current sort station and leave sort
        async function onDisconnect() {
            state.sortStation?.sortStationActivity?.forEach(async (x) => {
                await sortStationService.disconnectFromSortStation(x.sortStationId, x.userId);
            });
            router.push({ name: FloorTrakRouteTypes.HOME });
        }

        async function reInitComponent() {
            state.loading = true;
            Object.assign(state, initialState);
            await initComponent();
        }

        // pallet complete basically just closes the current transaction and reloads
        async function onPalletComplete() {
            reInitComponent();
        }

        function onModalCancel(): void {
            state.showUserSearch = false;
            state.newUser = undefined;
            state.showSortQuantity = false;
            state.selectedSortCategory = undefined;
            state.selectedSortItem = undefined;
            state.selectedSortQty = undefined;
            state.showRepairModal = false;
        }

        async function onMoveToGlass() {
            if (!transaction.value) {
                return;
            }

            const response = await confirm({
                title: getTranslation('core.common.areYouSure'),
                message: getTranslation('core.validation.movingToGlassWillUpdateExistingSortedItems'),
            });

            if (response) {
                state.loading = true;
                const moveResponse = await sortedItemService.moveToGlass(transaction.value.id);
                if (moveResponse.success) {
                    // refresh transaction since line items could be modified
                    await fetchReceivingShipment(transaction.value.id, ReceivingAction.SORT);

                    state.sortedItems = moveResponse.sortedItems;
                    state.tableComponentKey += 1;
                }
                state.loading = false;
            }
        }

        function checkCompleteItem(itemId: number) {
            const item = state.completeItemList.find((x) => x.item.id === itemId);
            if (item) {
                item.checked = !item.checked;
            }
        }

        function selectAllComplete() {
            state.completeItemList.forEach((x) => {
                x.checked = true;
            });
        }

        async function onOpenLoadComplete() {
            state.completeItemList = transaction.value?.transactionLines.filter((x) => !x.actualQuantity).map((x) => ({ item: x.item, checked: false })) ?? [];
            state.isRevertLoadComplete = false;
            state.showLoadCompleteModal = true;
        }

        async function onLoadComplete() {
            if (!transaction.value || !state.completeItemList?.some((x) => x.checked)) {
                state.showLoadCompleteModal = false;
                return;
            }

            state.loading = true;
            const loadCompleteResponse = await sortedItemService.loadComplete(
                transaction.value.id,
                state.completeItemList.filter((x) => x.checked).map((x) => x.item.id),
            );
            if (loadCompleteResponse.success) {
                // refresh transaction since line items will be modified
                await fetchReceivingShipment(transaction.value.id, ReceivingAction.SORT);
                state.sortedItems = loadCompleteResponse.sortedItems;
                state.tableComponentKey += 1;
            }

            state.loading = false;
            state.showLoadCompleteModal = false;
        }

        async function onOpenRevertLoadComplete() {
            state.completeItemList = transaction.value?.transactionLines.filter((x) => x.actualQuantity).map((x) => ({ item: x.item, checked: true })) ?? [];
            state.isRevertLoadComplete = true;
            state.showLoadCompleteModal = true;
        }

        async function onRevertLoadComplete() {
            if (!transaction.value || !state.completeItemList?.some((x) => x.checked)) {
                state.showLoadCompleteModal = false;
                return;
            }

            state.loading = true;
            const revertLoadCompleteResponse = await sortedItemService.revertLoadComplete(
                transaction.value.id,
                state.completeItemList.filter((x) => x.checked).map((x) => x.item.id),
            );
            if (revertLoadCompleteResponse.success) {
                // refresh transaction since line items will be modified
                await fetchReceivingShipment(transaction.value.id, ReceivingAction.SORT);
                state.sortedItems = revertLoadCompleteResponse.sortedItems;
                state.tableComponentKey += 1;
            }

            state.loading = false;
            state.showLoadCompleteModal = false;
        }

        function escape() {
            closeSearch();
            router.push({ name: FloorTrakRouteTypes.HOME });
        }

        function toCellName(category: InventoryCategory) {
            return `cell(category${category.id})`;
        }

        function openPrintLabelsModal(): void {
            state.showPrintLabelsModal = true;
        }

        async function printLabels() {
            state.showPrintLabelsModal = false;

            if (!state.labelQuantity) {
                notification.showError(getTranslation('core.validation.quantityMustBeSpecified'));
                return;
            }

            state.printingLabels = true;
            const labelUrl = await labelGenerationService.getInboundLabelsUrl(transaction.value.id, state.labelQuantity!);
            state.printingLabels = false;
            state.labelQuantity = undefined;
            if (labelUrl) {
                window.location.href = labelUrl;
            }
        }

        async function printInboundReceipt() {
            state.loading = true;
            setLoading(true);
            await receivingService.getTransactionReceipt(transaction.value.id);
            setLoading(false);
            state.loading = false;
        }

        function searchExistingCheck() {
            if (state.openSortStations?.length) {
                searchExisting();
            }
        }

        async function toggleTransactionAllOrCurrent(value: string) {
            state.transactionsAllOrCurrentSelected = value;
            await fetchSortedTotals();
        }

        async function confirmRepairQty(quantity: number) {
            var confirmResponse = await confirm({
                title: getTranslation('core.common.areYouSure'),
                message: getTranslation('core.validation.confirmSortQuantity'),
                vHtml: true,
            });

            if (confirmResponse) {
                updateRepairPartQty(quantity); 
            }
        }

        return {
            state,
            transaction,
            ItemTypeEnum,
            addItem,
            openItemSearch,
            table,
            searchExisting,
            onCancel,
            onDisconnect,
            onPalletComplete,
            onModalCancel,
            onMoveToGlass,
            onOpenLoadComplete,
            checkCompleteItem,
            selectAllComplete,
            onLoadComplete,
            onOpenRevertLoadComplete,
            onRevertLoadComplete,
            canSubmit,
            escape,
            userId,
            openUserSearch,
            selectNewUser,
            connectNewUser,
            disconnectUser,
            refresh,
            toCellName,
            usableCategories,
            openAddSortedQuantity,
            addSortedQuantity,
            percentCompleted,
            percentCompletedMessage,
            hasGlass,
            isCompleted,
            finishedGoodsInventoryCategory,
            glassInventoryCategory,
            getTranslation,
            getTitleCaseTranslation,
            printInboundReceipt,
            openPrintLabelsModal,
            printLabels,
            searchExistingCheck,
            Permissions,
            pageTitle,
            itemAllowsCategory,
            submitInlineRepair,
            populateRepairParts,
            updateRepairPartQty,
            repairDescription,
            transactionAllOrCurrentOptions,
            toggleTransactionAllOrCurrent,
            confirmRepairQty,
        };
    },
});
